import API from "@utils/request";

const WORK_ORDER_APPLY = '/index/';

/**
 * 工单列表
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressPageList(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderList', params)
    } catch (error) {
        return error;
    }
}

/**
 * 工单新增
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressAdd(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderAdd', params)
    } catch (error) {
        return error;
    }
}

/**
 * 工单编辑
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressEdit(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderUpdate', params)
    } catch (error) {
        return error;
    }
}

/**
 * 工单详情
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressDetail(id) {
    try {
        return await API.get(WORK_ORDER_APPLY + 'workOrderUpdate?id=' + id)
    } catch (error) {
        return error;
    }
}


/**
 * 工单删除
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressDel(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderDelete', params)
    } catch (error) {
        return error;
    }
}


/**
 * 工单流程节点添加
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressStepAdd(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderConfigAdd', params)
    } catch (error) {
        return error;
    }
}

/**
 * 工单流程节点列表
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressStepList(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderConfigList', params)
    } catch (error) {
        return error;
    }
}

/**
 * 工单流程节点删除
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressStepDel(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderConfigDelete', params)
    } catch (error) {
        return error;
    }
}

/**
 * 工单流程节点详情
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressStepDetail(id) {
    try {
        return await API.get(WORK_ORDER_APPLY + 'workOrderConfigEdit?id=' + id)
    } catch (error) {
        return error;
    }
}

/**
 * 工单流程节点更新
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressStepEdit(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'workOrderConfigEdit', params);
    } catch (error) {
        return error;
    }
}

/**
 * 工单表单字段保存
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressFormSave(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'formFieldSelfBuild', params);
    } catch (error) {
        return error;
    }
}

/**
 * 工单表单字段列表
 * @param {object} params 
 * @returns 
 */
export async function workOrderProgressFormList(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'formFieldSelfEcho', params);
    } catch (error) {
        return error;
    }
}

/**
 * 工单表单字段删除
 * @param {object} params 
 * @returns 
 */
 export async function workOrderProgressFormDel(params) {
    try {
        return await API.post(WORK_ORDER_APPLY + 'formFieldSelfDelete', params);
    } catch (error) {
        return error;
    }
}